import { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import { useAppSelector } from "../../store/hooks";
import { savePOD } from "../../services/podServices";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { ShipmentInterface, ShipmentStatus } from "../../interfaces";
import FingerprintReader from "../FingerprintReader";
import WebcamCapture from "../Webcam";
import {
  FormTextInput,
  FormFileUpload,
  FormTextAreaInput,
} from "../FormFields";
import {
  getShipment,
  alertService,
  loaderService,
  updateShipmentStatus,
} from "../../services";

interface OriginModalProps {
  openModal: boolean;
  shipmentNumbers: string[];
  shipments?: ShipmentInterface[];
  consigneeName: string;
  consigneeId: string;
  fingerprint?: string;
  setFingerprint: (fingerprint?: string) => void;
  image?: string;
  setImage: (image?: string) => void;
  onSubmit?: () => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OriginModal: FC<OriginModalProps> = ({
  openModal,
  setOpenModal,
  shipments,
  shipmentNumbers,
  consigneeName,
  consigneeId,
  fingerprint,
  setFingerprint,
  image,
  setImage,
  onSubmit = () => {},
}) => {
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);

  const [nameError, setNameError] = useState("");
  const [idError, setIdError] = useState("");
  const [name, setName] = useState(consigneeName);
  const [id, setId] = useState(consigneeId);
  const [observation, setObservation] = useState("");
  const [fingerprintTemplate, setFingerprintTemplate] = useState<
    string | undefined
  >();
  const [fingerprintUploaded, setFingerprintUploaded] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState(false);

  //   const handleSubmit = async () => {
  //     loaderService.start();

  //     if (!name) {
  //       loaderService.stop();
  //       setNameError("El nombre es requerido");
  //       return;
  //     }
  //     setOpenModal(false);

  //     let shipmentsToDeliver: ShipmentInterface[] = [];
  //     if (!shipments) {
  //       // Get all shipments
  //       for (const shipmentNumber of shipmentNumbers) {
  //         const shipment = await getShipment(shipmentNumber);
  //         if (!!shipment) {
  //           shipmentsToDeliver.push(shipment);
  //         }
  //       }
  //     } else {
  //       shipmentsToDeliver = shipments;
  //     }

  //     for (const shipment of shipmentsToDeliver) {
  //       // Create POD
  //       const podResponse = await savePOD(
  //         shipment.id!,
  //         shipment.consignee.id,
  //         name,
  //         observation,
  //         user.user!,
  //         user.businessUnit!
  //       );
  //       if (!podResponse || !!podResponse.didError || !podResponse.model) {
  //         alertService.error(
  //           `Hubo un error al crear el POD de la guía ${shipment.shipmentNumber}.`,
  //           podResponse.errorMessage
  //         );
  //         loaderService.stop();
  //         return;
  //       }

  //       // Update shipment status
  //       const response = await updateShipmentStatus(
  //         ShipmentStatus.DELIVERED,
  //         shipment.id,
  //         shipment.shipmentNumber,
  //         user.user ?? undefined
  //       );

  //       if (!!response.didError) {
  //         alertService.error(
  //           `Hubo un error al actualizar el estado de la guía ${shipment.shipmentNumber}.`
  //         );
  //         loaderService.stop();
  //         return;
  //       }
  //     }

  //     loaderService.stop();
  //     onSubmit();
  //   };

  const canSubmit = () => {
    return (fingerprintUploaded || photoUploaded) && !loading && name && id;
  };

  useEffect(() => {
    setName(consigneeName);
  }, [consigneeName]);

  useEffect(() => {
    setId(consigneeId);
  }, [consigneeId]);

  useEffect(() => {
    setPhotoUploaded(!!image);
  }, [image]);

  useEffect(() => {
    setFingerprintUploaded(!!fingerprint);
  }, [fingerprint]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="w-full max-w-[40rem]"
    >
      <div className="flex flex-col md:flex-row md:gap-2 mb-4">
        <p className="font-light text-xl">
          Entrega de guías:{" "}
          <span className="text-indigo-600">{shipmentNumbers.join(", ")}</span>
        </p>
      </div>

      <div className="flex justify-center items-center mb-4">
        <div className="bg-gray-100 p-4 rounded border border-gray-300 text-center">
          <p className="text-sm">
            <span className="font-bold">
              Declaro bajo fe de juramento, libre de apremio, coerción y por
              voluntad propia que:
            </span>
            <br />
            <span className="font-bold"> 1.</span> La encomienda consignada a
            <span className="font-bold"> TEALCA </span> tiene un origen, fines y
            naturaleza lícitos, no contiene ningún tipo de sustancia
            psicotrópica, estupefaciente, precursora o cualquiera señalada en la
            Ley Orgánica de Drogas ni bienes contrarios a la legislación
            venezolana vigente, y cumple con las{" "}
            <span className="font-bold">
              {" "}
              CONDICIONES GENERALES DEL SERVICIO{" "}
            </span>
            encontradas en{" "}
            <a
              className="text-indigo-600"
              href="https://www.tealca.com/legal/terminos-condiciones/"
            >
              https://www.tealca.com/legal/terminos-condiciones/
            </a>{" "}
            los cuales acepto íntegramente.
            <br />
            <span className="font-bold"> 2.</span> Acepto que la guía podrá ser
            objeto de inspección por parte de las autoridades competentes en
            materia de drogas u otras, por lo que libero de toda responsabilidad
            y mantendré indemne a <span className="font-bold"> TEALCA </span>,
            sus directivos y trabajadores, de toda responsabilidad en caso de
            efectuarse una retención o incautación de la guía por parte de las
            autoridades.
            <br />
            <span className="font-bold"> 3.</span> Soy responsable por todos
            aquellos daños, perjuicios y gastos ocasionados a{" "}
            <span className="font-bold"> TEALCA </span> y/o terceros producto de
            la falsedad o ausencia los datos aquí provistos y/o los documentos
            necesarios para transportar la encomienda.
          </p>
        </div>
      </div>

      <div className="mb-6">
        <FormTextInput
          label="Nombre del remitente"
          isRequiredLabel
          name="fullName"
          type="text"
          value={name}
          error={nameError}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-6">
        <FormTextInput
          label="Cédula de Identidad"
          isRequiredLabel
          name="identityCard"
          type="text"
          value={id}
          error={idError}
          onChange={(e) => setId(e.target.value)}
        />
      </div>

      <div className="flex flex-col items-center mb-6">
        <WebcamCapture image={image} setImage={setImage} />
      </div>
      <div className="flex flex-col mb-6">
        <FingerprintReader
          fingerprint={fingerprint}
          setFingerprint={setFingerprint}
          setTemplate={setFingerprintTemplate}
        />
      </div>

      <FormTextAreaInput
        rows={4}
        maxLength={150}
        id="observations"
        name="observations"
        label="Observaciones"
        className="resize-none"
        onChange={(e) => setObservation(e.target.value)}
        value={observation}
      />

      <hr className="my-4" />

      <div className="flex w-full items-center justify-between">
        <SecondaryButton className="w-32" onClick={() => setOpenModal(false)}>
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={!canSubmit()}
          className="w-32"
          onClick={() => setOpenModal(false)}
        >
          Guardar
        </PrimaryButton>
      </div>
    </Modal>
  );
};
